/* eslint-disable no-template-curly-in-string */
import {
  alertMessage,
  brandManager,
  commons,
  countries,
  daysOfWeek,
  kitchenManager,
  Locales,
  modulesLocale,
  reasonOnOff,
  scheduled,
  StatusLocale,
  storeManager,
  storeStatus,
} from "./types";

export const esLocale: Locales = {
  [commons.SAVE]: "Guardar",
  [commons.LOADING]: "Cargando",
  [commons.ERROR_TITLE]: "La página no existe o no tiene acceso...",
  [commons.ERROR_SUBTITLE]: "Porfavor contáctese con el administrador.",
  [commons.REQUIRED_FIELD]: "Valor obligatorio",
  [commons.QUANTITY_ERROR]: "La cantidad debe ser mayor a 0",
  [commons.ADD]: "Agregar",
  [commons.MODIFY]: "Modificar",
  [commons.VIEW_DETAIL]: "Ver detalle",
  [commons.CANCEL]: "Cancelar",
  [commons.CONTINUE]: "Continuar",
  [commons.SEARCH]: "Buscar",
  [commons.COUNTRY]: "País",
  [commons.CITY]: "Ciudad",
  [commons.LOCATION]: "Ubicación",
  [commons.FILTERS]: "Filtros",
  [commons.PLATFORM]: "Plataforma",
  [commons.TABLE_EMPTY]: "No hay registros que mostrar",
  [commons.STATUS]: "Status",
  [commons.BRAND]: "Marca",
  [commons.ACTION]: "Acción",
  [commons.UPDATE]: "Actualizar",
  [commons.SET_UP]: "Configurar",
  [commons.STORE]: "Tienda",
  [commons.SCHEDULE]: "Programar",
  [commons.TIMETABLE]: "Horario",
  [commons.TURN_ON]: "Encender",
  [commons.TURN_OFF]: "Apagar",
  [commons.ON]: "Encendido",
  [commons.OFF]: "Apagado",
  [commons.CREATE]: "Crear",
  [commons.THEORETICAL]: "Teórico",
  [commons.REASON]: "Motivo",
  [commons.START]: "Inicio",
  [commons.END]: "Fin",
  [commons.ID]: "Id",
  [commons.NAME]: "Nombre",
  [commons.KITCHEN]: "Cocina",
  [commons.ALL]: "Todos",
  [commons.RETURN]: "Regresar",
  [commons.ALL_CITIES]: "Todas",
  [commons.ALL_KITCHENS]: "Todas",
  [commons.DATE]: "Fecha",
  [commons.DELETE]: "Eliminar",
  [commons.ACCEPT]: "Aceptar",
  [commons.TIME_ZONE]: "Zona horaria",
  [commons.COUNTRIES]: "Paises",
  [commons.STATION_TYPE]: "Tipo estación",
  [commons.TYPE]: "Tipo",
  [commons.IMAGE]: "Imagen",
  [commons.UPLOAD_IMAGE]: "Subir imagen",
  [commons.CLIC_TO_UPLOAD_IMAGE]: "Clic para subir imagen",
  [commons.ADDRESS]: "Dirección",
  [commons.POLYGONS]: "Polígonos",
  [commons.ALERT]: "Alerta",
  [commons.WARNING]: "Advertencia",
  [commons.EXPECTED]: "Esperado(a)",
  [commons.LONGITUDE]: "Longitud",
  [commons.LATITUDE]: "Latitud",
  [commons.ACTIVE]: "Activo(a)",
  [commons.INACTIVE]: "Inactivo(a)",
  [commons.LOW]: "Baja",
  [commons.MEDIUM]: "Media",
  [commons.HIGH]: "Alta",
  [commons.COVERAGE]: "Cobertura",
  [commons.YES]: "Si",
  [commons.NO]: "No",
  [commons.ENTER]: "Ingresar",
  [commons.KITCHEN_NAME]: "Nombre cocina",

  [storeManager.TITLE]: "Gestor de tiendas",
  [storeManager.MARKETPLACE]: "Marketplace",
  [storeManager.IS_PRIORITY]: "Turbo",
  [storeManager.IS_PARENT]: "Tienda padre",
  [storeManager.STORES]: "Tiendas",
  [storeManager.SCHEDULED]: "Programados",

  [storeStatus.Upcoming]: "Para lanzamiento",
  [storeStatus.Launched]: "Abierto",
  [storeStatus.Paused]: "Cerrada temporal",
  [storeStatus.Terminated]: "Cerrada permanentemente",
  [storeStatus.OPEN]: "Abierta",
  [storeStatus.NOT_LAUNCHED]: "No lanzada",
  [storeStatus.PERM_CLOSED]: "Cerrada permanentemente",
  [storeStatus.TEMP_CLOSED]: "Cerrada temporalmente",
  [storeStatus.INTERNAL]: "Interno",
  [storeStatus.REMOTE]: "Remoto",

  [brandManager.TITLE]: "Gestor de marcas",

  [kitchenManager.TITLE]: "Gestor de cocinas",
  [kitchenManager.LINK_STATION]: "Asociar estaciones",
  [kitchenManager.LINK_STATION_SUCCESS]: "Estaciones asociadas correctamente",
  [kitchenManager.SETUP_TITLE]: "Configurar cocinas",
  [kitchenManager.PRODUCTION_CENTER]: "Centro de producción",
  [kitchenManager.KITCHEN]: "Cocina",
  [kitchenManager.BOTH]: "Ambas",
  [kitchenManager.RAPPIALLIESUSER]: "Usuario Rappi Aliados",
  [kitchenManager.RAPPIALLIESPASSWORD]: "Contraseña Rappi Aliados",
  [kitchenManager.VAT_NUMBER]: "Numero Identificación Tributaria",
  [kitchenManager.INVOICE_COMPANY_NAME]: "Nombre para facturación",
  [kitchenManager.POS_TERMINAL_ID]: "POS Terminal ID",
  [kitchenManager.CONFIGS]: "Configuraciones",

  [alertMessage.ROWS_SELECTED]: "Ha seleccionado ({{rows}}) registros.",
  [alertMessage.SUCCESSFUL_PROCESS]: "Proceso realizado exitosamente.",
  [alertMessage.FAILED_PROCESS]: "Ha ocurrido un error en el proceso.",

  [reasonOnOff.OPERATING_CORRECTLY]: "Operando correctamente",
  [reasonOnOff.STAFF_SHORTAGE]: "Falta de personal",
  [reasonOnOff.KITCHEN_OPENING_ISSUES]: "Problemas en apertura de cocina",
  [reasonOnOff.GAS_FAILURES]: "Fallas de gas",
  [reasonOnOff.INTERNET_FAILURES]: "Fallas de internet",
  [reasonOnOff.POWER_FAILURES]: "Fallas de energía",
  [reasonOnOff.WATER_FAILURES]: "Fallas de agua",
  [reasonOnOff.STOCKOUTS]: "Stockouts",
  [reasonOnOff.SALES_OVERFLOW]: "Exceso de ventas",
  [reasonOnOff.SPECIAL_EVENTS]: "Eventos especiales",
  [reasonOnOff.SCHEDULED_MAINTENANCE]: "Mantenimiento programado",
  [reasonOnOff.RELOCATION]: "Mudanza o traslado",
  [reasonOnOff.STAFF_TRAINING]: "Capacitación del personal",
  [reasonOnOff.OUT_OF_SERVICE_BRAND]: "Marca fuera de servicio",
  [reasonOnOff.INTEGRATION_LACK]: "Falta de integración",
  [reasonOnOff.WITHOUT_REASON]: "Sin razón",
  [reasonOnOff.BY_SYSTEM]: "Por sistema",
  [reasonOnOff.CLOSED_BY_PLATFORM]: "Cerrado por plataforma",

  [daysOfWeek.MONDAY]: "Lunes",
  [daysOfWeek.TUESDAY]: "Martes",
  [daysOfWeek.WEDNESDAY]: "Miércoles",
  [daysOfWeek.THURSDAY]: "Jueves",
  [daysOfWeek.FRIDAY]: "Viernes",
  [daysOfWeek.SATURDAY]: "Sábado",
  [daysOfWeek.SUNDAY]: "Domingo",

  [scheduled.CHANGE_STATUS_MESSAGE]: "¿Desea archivar({{nro}}) programados?",
  [scheduled.ARCHIVE]: "Archivar",

  [countries.COL]: "Colombia",
  [countries.PER]: "Perú",
  [countries.BRA]: "Brasil",
  [countries.MEX]: "México",
  [countries.ECU]: "Ecuador",

  [modulesLocale.SELECT_MODULE_TITLE]: "Seleccione el módulo",
  [modulesLocale.SELECT_MODULE_SUBTITLE]: "Seleccione la herramienta",
  [modulesLocale.ADMIN_TITLE]: "Admin",
  [modulesLocale.ADMIN_SUBTITLE]:
    "Selecciona la herramienta que deseas utilizar para administrar tus tiendas.",

  [StatusLocale.PENDING]: "Pendiente",
  [StatusLocale.DONE]: "Hecho",
  [StatusLocale.ARCHIVED]: "Archivado",
};
