import { Grid, TextField } from "@mui/material";
import { KitchenConfig } from "core/kitchens/entities/Kitchen";
import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormState } from "../utils";

interface Props {
  kitchenConfig: KitchenConfig;
  config: Record<KitchenConfig, string> | undefined;
  setFormState: (value: SetStateAction<FormState>) => void;
}

const ConfigCard = (props: Props): React.ReactElement => {
  const { kitchenConfig, config, setFormState } = props;
  const { t } = useTranslation();

  const [value, setValue] = useState<string>("");

  useEffect(() => {
    if (!config?.[kitchenConfig]) return;
    setValue(config[kitchenConfig]);
  }, [config?.[kitchenConfig]]);

  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      config: {
        ...(prev.config ?? {}),
        [kitchenConfig]: value,
      } as Record<KitchenConfig, string>,
    }));
  }, [kitchenConfig, value]);

  return (
    <Grid item xs={12} container columnSpacing={3} rowSpacing={1}>
      <Grid item xs={6}>
        <TextField
          name={kitchenConfig.toString()}
          value={t(`kitchenManager.${kitchenConfig}`)}
          disabled={true}
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          value={value}
          size="small"
          fullWidth
          onChange={(event) => setValue(event.target.value)}
        />
      </Grid>
    </Grid>
  );
};

export default ConfigCard;
