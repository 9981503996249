import { KOSPageBox, KOSSelectedResult } from "@foodology-co/alejandria";
import PageHeader from "app/components/PageHeader";
import { commons, kitchenManager } from "app/i18n/types";
import { Kitchen } from "core/kitchens/entities/Kitchen";
import {
  getAllKitchens,
  upsertKitchen,
} from "core/kitchens/repository/kitchenRepo";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import KitchenManager from "../../components/Kitchen/Manager";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { StationConfigModal } from "app/components/station/config/Modal";
import KitchenFilters from "./Filters";
import KitchenTable from "app/components/Kitchen/Table";
import { useMount } from "react-use";
import { Actions, KitchenModal } from "utils/modal";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";

interface Props {}

const ModuleKitchens: FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const [kitchens, setKitchens] = useState<Kitchen[]>([]);
  const [kitchensFiltered, setKitchensFiltered] = useState<Kitchen[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<KOSSelectedResult[]>([]);
  const [openSetUpDialog, setOpenSetUpDialog] = useState<boolean>(false);
  const [modal, setModal] = useState<KitchenModal>();

  const getData = () => {
    setSelectedItems([]);
    setLoading(true);
    setKitchens([]);
    getAllKitchens()
      .then((result) => {
        setKitchens(result);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useMount(() => {
    getData();
  });

  const handleSaveKitchen = async (editedKitchen: Kitchen) => {
    await upsertKitchen(editedKitchen);
    getData();
    setModal(undefined);
  };

  return (
    <KOSPageBox>
      {openSetUpDialog && (
        <StationConfigModal
          onClose={() => setOpenSetUpDialog(false)}
          kitchens={selectedItems.map((item) => item.kitchenId)}
        />
      )}
      <PageHeader
        title={kitchenManager.TITLE}
        rightArea={[
          {
            children: t(commons.CREATE),
            variant: "contained",
            startIcon: <AddOutlinedIcon />,
            onClick: () =>
              setModal({
                open: true,
                action: Actions.add,
                onClose: () => setModal(undefined),
              }),
          },
          {
            children: t(commons.SET_UP),
            variant: "outlined",
            color: "warning",
            startIcon: <SettingsOutlinedIcon />,
            onClick: () => setOpenSetUpDialog(true),
            disabled: selectedItems.length === 0,
          },
          {
            children: t(commons.UPDATE),
            variant: "outlined",
            startIcon: <RestartAltOutlinedIcon />,
            onClick: getData,
          },
        ]}
      />
      <KitchenFilters
        kitchens={kitchens}
        setFilteredKitchens={setKitchensFiltered}
        loading={loading}
      />
      <KitchenTable
        loading={loading}
        kitchensFiltered={kitchensFiltered}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        setModal={setModal}
      />

      {modal?.open && (
        <KitchenManager
          {...modal}
          onSave={handleSaveKitchen}
          onSuccess={getData}
          onClose={() => setModal(undefined)}
        />
      )}
    </KOSPageBox>
  );
};

export default ModuleKitchens;
