import { Add } from "@mui/icons-material";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import KitchenSelector from "app/components/Kitchen/Selector";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import CustomSelector from "app/components/common/Selector";
import { useAlert } from "app/hooks/useAlert";
import { useModal } from "app/hooks/useModal";
import { commons, kitchenManager } from "app/i18n/types";
import {
  Kitchen,
  KitchenConfig,
  TypeOfKitchen,
} from "core/kitchens/entities/Kitchen";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Actions, handleModalIcon } from "utils/modal";

import ConfigCard from "./config/Card";
import {
  BaseDialogProps,
  defaultFormState,
  FormState,
  handleValidations,
} from "./utils";

interface Props extends BaseDialogProps {
  kitchen?: Kitchen;
}

const HandleForm: React.FC<Props> = (props) => {
  const { onClose, onSave, action, onSuccess, kitchen: initialValues } = props;

  const { t } = useTranslation();
  const alert = useAlert();
  const modal = useModal();

  const toFormState = (): FormState =>
    action === Actions.edit
      ? {
          ...initialValues,
          city: undefined,
        }
      : { ...defaultFormState };

  const [formState, setFormState] = useState<FormState>(() => toFormState());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [kitchenConfig, setkitchenConfig] = useState<KitchenConfig | null>(
    null
  );

  useEffect(() => {
    setFormState(() => toFormState());
    setTimeout(() => {
      setFormState((prev) => ({
        ...prev,
        city: initialValues?.city,
      }));
    }, 200);
  }, [initialValues]);

  useEffect(() => {
    if (formState) {
      modal.validator(handleValidations, formState);
    }
  }, [formState]);

  const handleSubmit = useCallback(async () => {
    if (formState && handleValidations.isValidSync(formState)) {
      setIsLoading(true);
      try {
        await onSave(formState as Kitchen);
        alert.success();
        onSuccess();
        onClose();
      } catch {
        alert.error();
      } finally {
        setIsLoading(false);
      }
    }
  }, [formState, onSave, alert, onSuccess, onClose, modal.errors]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (formState) {
      const { name, value } = e.target;

      let updatedValue = value;

      if (name === "kitchenId") {
        updatedValue = value.toUpperCase();
      }

      const updatedFormState = {
        ...formState,
        [name]: updatedValue,
      };

      setFormState(updatedFormState);
    }
  };

  const handleSelectChange = (field: keyof Kitchen, value: any) => {
    if (formState) {
      setFormState({
        ...formState,
        [field]: value,
      });
    }
  };

  const addConfig = useCallback(() => {
    if (!kitchenConfig) return;
    console.log({ value: formState.config?.[kitchenConfig], formState });
    if (formState.config?.[kitchenConfig]) return;

    setFormState((prev) => ({
      ...prev,
      config: {
        ...(prev.config ?? {}),
        [kitchenConfig]: "",
      } as Record<KitchenConfig, string>,
    }));

    setkitchenConfig(null);
  }, [kitchenConfig, formState]);

  if (!formState) {
    return <LinearProgress />;
  }

  return (
    <Grid container spacing={3}>
      {isLoading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            name="kitchenId"
            label="Kitchen ID"
            value={formState.kitchenId ?? ""}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: handleModalIcon(modal.errors["kitchenId"]),
            }}
            disabled={!(action === Actions.add)}
            focused={!!formState.kitchenId}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            name="name"
            label={t(commons.NAME)}
            value={formState.name ?? ""}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: handleModalIcon(modal.errors["name"]),
            }}
            autoComplete="off"
            focused={!!formState.name}
          />
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <CustomSelector
          options={Object.keys(TypeOfKitchen)}
          optionPrefix="kitchenManager"
          label={commons.TYPE}
          selected={formState.type ?? ""}
          setSelected={(newValue) => handleSelectChange("type", newValue)}
          endAdornment={handleModalIcon(modal.errors["type"])}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            name="locationCode"
            label="Location Code"
            value={formState.locationCode ?? ""}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: handleModalIcon(modal.errors["locationCode"]),
            }}
            autoComplete="off"
            focused={!!formState.locationCode}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <KitchenSelector
          selected={{
            country: formState.country,
            city: formState.city?.code,
          }}
          onChange={{
            country: (value) => handleSelectChange("country", value?.code),
            city: (value) => {
              if (value) {
                setFormState({
                  ...formState,
                  city: {
                    _id: value.id,
                    code: value.code,
                    name: value.name,
                  },
                });
              } else {
                setFormState({
                  ...formState,
                  city: undefined,
                });
              }
            },
          }}
          hide={{
            kitchen: true,
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            name="address"
            label={t(commons.ADDRESS)}
            value={formState.address ?? ""}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: handleModalIcon(modal.errors["address"]),
            }}
            autoComplete="off"
            focused={!!formState.address}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            name="cityIdDidi"
            label="City ID Didi"
            value={formState.cityIdDidi ?? ""}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: handleModalIcon(modal.errors["cityIdDidi"]),
            }}
            autoComplete="off"
            focused={!!formState.cityIdDidi}
          />
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>{t(commons.STATUS)}</InputLabel>
          <Select
            label={t(commons.STATUS)}
            value={formState.status ?? ""}
            onChange={(event) => {
              const newValue = event.target.value as Kitchen["status"];
              handleSelectChange("status", newValue);
            }}
            endAdornment={handleModalIcon(modal.errors["status"])}
            autoComplete="off"
          >
            <MenuItem value="ACTIVE">{t(commons.ON)}</MenuItem>
            <MenuItem value="INACTIVE">{t(commons.OFF)}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            name="tier"
            label="Tier"
            type="number"
            value={formState.tier ?? 0}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: handleModalIcon(modal.errors["tier"]),
            }}
            autoComplete="off"
            focused={!!formState.tier}
          />
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            name="rappiAlliesUser"
            label={t(kitchenManager.RAPPIALLIESUSER)}
            value={formState.rappiAlliesUser ?? ""}
            onChange={handleInputChange}
            autoComplete="off"
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            name="rappiAlliesPassword"
            label={t(kitchenManager.RAPPIALLIESPASSWORD)}
            value={formState.rappiAlliesPassword ?? ""}
            onChange={handleInputChange}
            autoComplete="off"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={8}>
        <FormControl fullWidth>
          <InputLabel>{t(kitchenManager.CONFIGS)}</InputLabel>
          <Select
            label={t(kitchenManager.CONFIGS)}
            value={kitchenConfig}
            onChange={(event) => {
              const newValue = event.target.value;
              if (!newValue) return;
              setkitchenConfig(newValue as KitchenConfig);
            }}
          >
            {Object.keys(KitchenConfig).map((option) => (
              <MenuItem key={`config-${option}`} value={option}>
                {t(`kitchenManager.${option}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="outlined"
          startIcon={<Add />}
          sx={{ minHeight: "100%" }}
          disabled={!kitchenConfig}
          onClick={() => addConfig()}
          fullWidth
        >
          {t(commons.ADD)}
        </Button>
      </Grid>

      {Object.keys(formState.config ?? {}).map((config) => (
        <ConfigCard
          key={`ConfigCard${config}`}
          kitchenConfig={config as KitchenConfig}
          config={formState.config}
          setFormState={setFormState}
        />
      ))}

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <ManagerDialogFooter
          onCancel={onClose}
          mainButton={{
            children: t(commons.SAVE),
            onClick: handleSubmit,
            disabled: isLoading || !!Object.keys(modal.errors).length,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default HandleForm;
